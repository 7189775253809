// import logo from "./logo.svg";
import "./css/App.css";

import React from "react";
import Timeline from "./components/Timeline";
import Icon from "./components/Icon";
import Card from "./components/Card";
import EducationCard from "./components/EducationCard";
import Header from "./components/Header";
import Footer from "./components/Footer";

import TI_LOGO from "./assets/ti_logo.png";
import ME from "./assets/me.jpeg";
import BYU_LOGO from "./assets/byu_logo.png";
import DUMMY from "./assets/other_img.jpeg";
import LINKED_IN from "./assets/linked_in_logo.png";
import GITHUB from "./assets/github_logo.png";
import RESUME from "./assets/resume.jpeg";
import JAVA from "./assets/java.svg";
import RESUME_PDF from "./assets/poulson_resume.pdf";

import DIGDUG from "./assets/digdug.mp4";
import WORDLE from "./assets/wordle.mp4";
import BATTLESHIP from "./assets/battleship.mp4";

import TWEETER_SEQUENCE from "./assets/tweeter_sequence.mp4";
import STORY_UML from "./assets/story_uml.mp4";

import TAPE_TURNAROUND from "./assets/tape_turnaround.mp4";

import LEGO from "./assets/lego.mp4";
import BUG from "./assets/bug.mp4";

import DIJKSTRAS from "./assets/dijkstras.mov";
import CONVEX_HULL from "./assets/convex_hull.mov";
import TSP from "./assets/tsp.mov";

const App = () => {
  const images = [DUMMY, TI_LOGO, DUMMY];
  const spoonfingerImages = [WORDLE, DIGDUG, BATTLESHIP];
  const tweeterAssets = [TWEETER_SEQUENCE, STORY_UML];
  const cgAssets = [LEGO, TAPE_TURNAROUND, BUG];
  const algorithmAssets = [DIJKSTRAS, CONVEX_HULL, TSP];
  const additionalDetails =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

  return (
    <div className="appContainer">
      <Header />

      <section id="about">
        <div className="spacer"></div>

        <h1 className="name">Nat Poulson</h1>

        <div className="tagline">Student and Software Developer</div>

        <img className="profilePicture" src={ME} alt="Picture of Nat Poulson" />

        <div className="socials">
          <Icon
            title="Github"
            image={GITHUB}
            link="https://github.com/Nathaniel150"
          />
          <Icon
            title="LinkedIn"
            image={LINKED_IN}
            link="https://www.linkedin.com/in/nathanielpoulsonbyu/"
          />
          <Icon title="Resume" image={RESUME} link={RESUME_PDF} />
        </div>
      </section>

      <section id="experience">
        <div className="spacer"></div>
        <h1 className="timelineHeader">Work Experience</h1>
        <div className="timeLineContainer">
          <Timeline />
          <div className="timeline-line"></div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {/* <section id="skills">
        <div className="spacer"></div>
        <h1 className="timelineHeader">Technical Skills</h1>
        <div className="skillsContainer">
          <div className="skills">
            <Icon image={JAVA} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
            <Icon image={TI_LOGO} />
          </div>
        </div>
      </section> */}
      <section id="projects">
        <div className="spacer"></div>

        <h1 className="timelineHeader">Projects</h1>

        <Card
          projectName="Spoonfingers Mobile App"
          projectDescription="Mobile game I created complete with an original story, visual assets, and multiple puzzle-like minigames. It is built using React Native, Git, JavaScript, and Expo. Available on the Apple app store and the Google play store."
          images={spoonfingerImages}
          additionalDetails={additionalDetails}
        />
        <Card
          projectName="Algorithms and Analysis"
          projectDescription="An implementation of popular algorithms such as Dijkstra's, Convex Hull, and Travelling Salesperson. I coded the project using Python, and created written reports for each algorithm. In each report, I did an in-depth analysis of the time and space complexity of each algorithm and data structure I used, including comparisons of theoretical performance versus actual observed performance."
          images={algorithmAssets}
          additionalDetails={additionalDetails}
        />
        <Card
          projectName="Tweeter"
          projectDescription="A Twitter clone project, written with Java and Android Studio, focused on using good software design principles. Used MVC, template method, dependency injection, and generics to write good quality, reusable code. The backend relies on cloud computing resources, and it structured to allow for scalability to large userbases. Built using Java, Android Studio, AWS Lambda, AWS SQS Queue, AWS DynamoDB."
          images={tweeterAssets}
          additionalDetails={additionalDetails}
        />
        <Card
          projectName="3D CG Projects"
          projectDescription="Images and videos made using 3D modeling and rendering tools like Maya, Houdini, and Adobe Creative Suite. Work on these projects included all modeling, texturing, staging, lighting, and rendering to create the finished product."
          images={cgAssets}
          additionalDetails={additionalDetails}
        />
      </section>

      <section id="education">
        <div className="spacer"></div>

        <h1 className="timelineHeader">Education</h1>

        <EducationCard
          university="Brigham Young University"
          startDate="2020"
          endDate="2024"
          major="Computer Science"
          minor="Mathematics"
          image={BYU_LOGO}
        />
      </section>
      <div className="spacer"></div>
      <div className="spacer"></div>

      <Footer />
    </div>
  );
};

export default App;
