import React, { useState } from "react";
import "../css/ImageGallery.css"; // Import the CSS file for styling
import ReactPlayer from "react-player";
const VideoGallery = ({ images }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (vid) => {
    setSelectedVideo(vid);
  };

  const handleCloseVideo = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="image-gallery">
      {images.map((vid, index) => (
        <ReactPlayer
          key={index}
          url={vid}
          //   controls={t}
          className="gallery-image"
          muted={true}
          playing={true}
          loop={true}
          width="100%"
          height="auto"
          onClick={() => handleVideoClick(vid)}
        />
        // <video
        //   autoPlay
        //   loop
        //   key={index}
        //   src={image}
        //   alt={`Image ${index + 1}`}
        //   className="gallery-image"
        //   onClick={() => handleImageClick(image)}
        // />
      ))}
      {selectedVideo && (
        <div className="expanded-image-overlay" onClick={handleCloseVideo}>
          <ReactPlayer
            url={selectedVideo}
            className="expanded-image"
            muted={true}
            playing={true}
            loop={true}
          />
        </div>
      )}
    </div>
  );
};

export default VideoGallery;
