import React from "react";
import "../css/Icon.css";

const Icon = ({ title, image, link }) => {
  return (
    <div className="icon">
      {link ? (
        <a title={title} href={link} target="_blank">
          <img src={image} alt="Icon" />
        </a>
      ) : (
        <img src={image} alt="Icon" />
      )}
    </div>
  );
};

export default Icon;
