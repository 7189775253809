import React from "react";
import "../css/EducationCard.css"; // Import the CSS file for styling

const EducationCard = ({
  university,
  startDate,
  endDate,
  major,
  minor,
  image,
}) => {
  return (
    <div className="education-card">
      <div className="education-header">
        <img src={image} alt="University Logo" className="university-logo" />
        <div className="university-info">
          <h2 className="university-name">{university}</h2>
          <div className="date-range">
            {startDate} - {endDate}
          </div>
        </div>
      </div>
      <div className="education-details">
        <div className="info-item">
          <span className="label">Major:</span> {major}
        </div>
        <div className="info-item">
          <span className="label">Minor:</span> {minor}
        </div>
        <div className="info-item">
          <span className="label">Overall GPA:</span> 3.98/4.00
        </div>
        <div className="info-item">
          <span className="label">Major GPA:</span> 4.00/4.00
        </div>
        <div className="info-item">
          <span className="label">Coursework:</span>Software Engineering, Intro
          to AI, Network/Graph Data Science, Data Structures, Algorithms and
          Analysis, Web Programming, Software Design, Database Modeling,
          Computer Graphics, Computer Systems, Discrete Mathematics, Advanced
          Programming, Statistics, Linear Algebra, Calculus I, Calculus II,
          Calculus III, Graph Theory
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
