import React from "react";
import "../css/Header.css"; // Import the CSS file for styling

const Header = () => {
  const handleScroll = (event, targetId) => {
    event.preventDefault();

    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <header className="header">
      <nav className="navbar">
        <ul className="nav-list">
          <li>
            <a href="#about" onClick={(e) => handleScroll(e, "#about")}>
              About
            </a>
          </li>
          <li>
            <a
              href="#experience"
              onClick={(e) => handleScroll(e, "#experience")}
            >
              Experience
            </a>
          </li>
          {/* <li>
            <a href="#skills" onClick={(e) => handleScroll(e, "#skills")}>
              Skills
            </a>
          </li> */}
          <li>
            <a href="#projects" onClick={(e) => handleScroll(e, "#projects")}>
              Projects
            </a>
          </li>
          <li>
            <a href="#education" onClick={(e) => handleScroll(e, "#education")}>
              Education
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
