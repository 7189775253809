import React from "react";
import "../css/Footer.css"; // Import the CSS file for styling

import Icon from "./Icon";

import LINKED_IN from "../assets/linked_in_logo.png";
import GITHUB from "../assets/github_logo.png";
import RESUME from "../assets/resume.jpeg";
import RESUME_PDF from "../assets/poulson_resume.pdf";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-icons">
        <Icon
          title="Github"
          image={GITHUB}
          link="https://github.com/Nathaniel150"
        />
        <Icon
          title="LinkedIn"
          image={LINKED_IN}
          link="https://www.linkedin.com/in/nathanielpoulsonbyu/"
        />
        <Icon title="Resume" image={RESUME} link={RESUME_PDF} />
      </div>
    </footer>
  );
};

export default Footer;
