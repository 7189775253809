import React from "react";
import Icon from "./Icon";
import "../css/Timeline.css";

import BREVIUM from "../assets/brevium_logo.png";
import NCINO from "../assets/ncino_logo.png";
import TI from "../assets/ti_logo.png";
import FHTL from "../assets/fhtlLogo.png";
import BYU from "../assets/byu_logo.png";

const Timeline = () => {
  const workExperience = [
    {
      id: 1,
      title: "Software Engineer Intern (Upcoming)",
      image: NCINO,
      company: "nCino",
      date: "May 2024 - Aug 2024",
      description: ["TBD"],
      skillsUsed: ["TBD"],
    },
    {
      id: 2,
      title: "Software Engineer Intern",
      image: BREVIUM,
      company: "Brevium",
      date: "Sep 2023 - Dec 2023",
      description: [
        "Automated sales demo software, using SQL stored procedures and C#, to eliminate manual setup errors and speedup process by 80% for sales team",
        "Updated deployment scripts to automatically build and release my software updates to customers",
        "Actively collaborated with team members within an Agile environment to conceptualize, develop, test, and deploy high-quality software solutions to customers satisfaction",
      ],
      skillsUsed: ["SQL", "C#", "Jira", "Git"],
    },
    {
      id: 3,
      title: "Software Engineer Intern",
      image: TI,
      company: "Texas Instruments",
      date: "May 2023 - Aug 2023",
      description: [
        "Implemented a scheduling tool, based on customer needs, that reduced time spent scheduling on call rotations by 10x",
        "Contributed to weekly sprints in an Agile team environment to quickly improve and modernize company systems",
      ],
      skillsUsed: [
        "JavaScript",
        "C#",
        "ASP.NET",
        "Visual Studio",
        "SQL",
        "HTML",
        "CSS",
        "Git",
        "Jira",
      ],
    },
    {
      id: 4,
      title: "Software Developer",
      image: FHTL,
      company: "Family History Technology Lab",
      date: "Aug 2021 - May 2023",
      description: [
        "Refactored an Angular application that has served over 1,000,000 users to be more performant and stable",
        "Developed a mobile app, Indexing GO, using React Native and moved it to production on the Apple app store and Google play store",
        "Directed team members and led development to complete a React.js development project in under 3 months",
      ],
      skillsUsed: [
        "React.js",
        "Angular",
        "AWS",
        "DynamoDB",
        "PostgreSQL",
        "JavaScript",
        "SQL",
        "Git",
      ],
    },
    {
      id: 5,
      title: "Full Stack Web Developer",
      image: BYU,
      company: "Brigham Young University",
      date: "Sep 2022 - Dec 2022",
      description: [
        "Completed a long-term initiative that sped up an existing system by 15x and allowed for users to make more sophisticated data queries on the website",
        "Maintained and improved web application to meet the needs of 40+ professors and 1000+ CS students",
      ],
      skillsUsed: ["Python", "Django", "Docker", "JavaScript", "SQL", "Jira"],
    },
    // Add more work experiences here
  ];

  return (
    <div className="timeline">
      {workExperience.map((experience, index) => (
        <div
          key={experience.id}
          className={`timeline-card ${index % 2 === 0 ? "left" : "right"}`}
        >
          <div>
            <div className="headerContainer">
              <div>
                <h3>{experience.title}</h3>
                <h4>{experience.company}</h4>
                <p>{experience.date}</p>
              </div>
              <div>
                <img
                  className="timeLineImage"
                  src={experience.image}
                  alt="Icon"
                />
              </div>
            </div>
            {/* Hard code remove this for Brevium */}
            <ul className={experience.company == "nCino" ? "hidden" : ""}>
              {experience.description.map((bullet, index) => {
                return <li>{bullet}</li>;
              })}
            </ul>
          </div>
          {/* Hard code remove this for Brevium */}
          <div className={experience.company == "nCino" ? "hidden" : ""}>
            Skills:
            <ul>
              <li>
                {experience.skillsUsed.map((skill, index) => {
                  if (index == experience.skillsUsed.length - 1) {
                    return <span>{skill} </span>;
                  }
                  return <span>{skill}, </span>;
                })}
              </li>
            </ul>
          </div>
          <div
            className={`${
              index % 2 === 0 ? "trianglePointerRight" : "trianglePointerLeft"
            }`}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
