import React, { useState } from "react";
import "../css/Card.css"; // Import the CSS file for additional styling

import ImageGallery from "./ImageGallery";

const Card = ({
  projectName,
  projectDescription,
  images,
  additionalDetails,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`card ${expanded ? "expanded" : ""}`}>
      <div>
        <div>
          <h2 className="project-name">{projectName}</h2>
          <p className="project-description">{projectDescription}</p>
        </div>
        <div className="imageContainer">
          <ImageGallery images={images} />
        </div>
      </div>
      {/* <div
        className={expanded ? "close-button" : "expand-button"}
        onClick={toggleExpand}
      ></div> */}
      {/* {expanded && (
        <div className="additional-details">
          <h3>Additional Details</h3>
          <p>{additionalDetails}</p>
        </div>
      )} */}
    </div>
  );
};

export default Card;
